<template>
  <div class="component-container">
    <component :is="componentId" :pageData="pageData" @changePage="changePage"></component>
  </div>
</template>

<script>

export default {
  name: 'SystemAccess',
  components: {
    SystemAccessTable: () => import('./pages/SystemAccessTable'),
    SystemAccessMng: () => import('./pages/SystemAccessMng'),
    SystemAccessDetail: () => import('./pages/SystemAccessDetail')
  },
  data() {
    return {
      componentId: 'SystemAccessTable',
      pageData: ''
    }
  },
  methods: {
    changePage(params) {
      this.pageData = params.pageData
      this.componentId = params.pageId
    }
  }
}
</script>
<style scoped lang="css">
.component-container{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>
